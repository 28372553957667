import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import ServiceCategoryTitle from '../../ui-components/general/ServiceCategoryTitle';
import {categories, categoryIcons} from '../constants/categories';
import {paymentIcon, useStyles} from '../../ui-components';
import AccordionRow from '../../ui-components/general/AccordionRow';
import SectionDetails from '../../ui-components/general/SectionDetails';
import {services} from '../constants/services';
import {personTypes} from '../constants/person';
import {maskIban} from '../utils/conversion';
import {journeyContinuationServices} from '../constants/journeyContinuationServices';
import {serviceTypes} from '../../mia-entities';

const groupServicesByCategory = services => {
    if (!services || services.length === 0) return null;
    const sortedServices = {};
    services.forEach(service => {
        switch (service.type) {
            case serviceTypes.BROKEN_GLASS:
            case serviceTypes.MARTEN_BITE:
            case serviceTypes.ROADKILL:
            case serviceTypes.ELEMENTAL: {
                if (!sortedServices[categories.AID]) {
                    sortedServices[categories.AID] = [service];
                    break;
                }
                sortedServices[categories.AID].push(service);
                break;
            }
            case serviceTypes.ACCOMMODATION:
            case serviceTypes.FURTHER_JOURNEY:
            case serviceTypes.RENTAL_CAR:
            case serviceTypes.SHORT_TRIP: {
                if (!sortedServices[categories.JOURNEY_CONTINUATION_AND_ACCOMMODATION]) {
                    sortedServices[categories.JOURNEY_CONTINUATION_AND_ACCOMMODATION] = [service];
                    break;
                }
                sortedServices[categories.JOURNEY_CONTINUATION_AND_ACCOMMODATION].push(service);
                break;
            }
            case serviceTypes.OTHER: {
                if (!sortedServices[categories.OTHER]) {
                    sortedServices[categories.OTHER] = [service];
                    break;
                }
                sortedServices[categories.OTHER].push(service);
                break;
            }
            case serviceTypes.ROADSIDE_ASSISTANCE:
            case serviceTypes.TOWING:
            case serviceTypes.VEHICLE_OPENING: {
                if (!sortedServices[categories.ROADSIDE_ASSISTANCE_AND_TOWING]) {
                    sortedServices[categories.ROADSIDE_ASSISTANCE_AND_TOWING] = [service];
                    break;
                }
                sortedServices[categories.ROADSIDE_ASSISTANCE_AND_TOWING].push(service);
                break;
            }
        }
    });
    return sortedServices;
};

const InvoiceSummaryScreen = props => {
    const {cx} = useStyles();
    const {files, invoiceSubmission, isEditable, invoicingRemarks, paymentInfo} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateScreen = createTranslateShorthand('summary_screen');
    const [sortedServices] = useState(groupServicesByCategory(invoiceSubmission?.services));

    const composeFullAddress = address => {
        if (!address || typeof address !== 'object') return '';
        return Object.keys(address).map(key => {
            if (!address[key]
                || address[key] === ''
                || key === 'formattedAddress'
                || key === 'persistenceState'
            ) return;
            return address[key];
        }).filter(value => !!value).join(', ');
    };

    return (
        <Fragment>
            <h2
                className={cx([
                    'global!ace-u-typography--variant-h2',
                    'global!ace-u-margin--small-bottom-24',
                ])}
            >
                {translateScreen('title.summary')}
            </h2>
            {sortedServices && Object.keys(sortedServices).map(key => (
                <Fragment key={key}>
                    <ServiceCategoryTitle
                        className={cx(['global!ace-u-margin--small-bottom-24'])}
                        icon={categoryIcons[key]}
                    >
                        {translate(`global.service_category.${key?.toLowerCase()}`)}
                    </ServiceCategoryTitle>
                    {sortedServices[key].map((service, index) => (
                        <AccordionRow
                            key={`${service}-${index}`}
                            title={translate(`global.service_type.${service.type?.toLowerCase()}`)}
                            className={cx('global!ace-u-margin--small-bottom-24')}
                            isDefaultOpenState={false}
                        >
                            <SectionDetails
                                className={cx('global!ace-u-margin--small-bottom-24')}
                                isEditable={isEditable}
                            >
                                <p
                                    className={cx([
                                        'global!ace-u-typography--variant-body-medium',
                                        'global!ace-u-margin--small-bottom-8',
                                    ])}
                                >
                                    {translateScreen('text.performance')}
                                </p>
                                {key === categories.AID && (
                                    <p>
                                        {`${translateScreen('text.type_of_aid')} ${translate(`global.service_type.${service.type?.toLowerCase()}`) || '/'}`}
                                    </p>
                                )}
                                {(key === categories.AID || key === categories.ROADSIDE_ASSISTANCE_AND_TOWING) && (
                                    <Fragment>
                                        <p className={cx('global!ace-u-typography--variant-body-medium')}>{translateScreen('text.driver')}</p>
                                        <p>{`${translateScreen('text.first_name')} ${service.driver?.name || '/'}`}</p>
                                        <p>{`${translateScreen('text.surname')} ${service.driver?.surname || '/'}`}
                                        </p>
                                        {service.driver?.type !== personTypes.MEMBER && (
                                        <Fragment>
                                            <p>{`${translateScreen('text.street')} ${service.driver?.address?.street || '/'}`}</p>
                                            <p>{`${translateScreen('text.postcode')} ${service.driver?.address?.postCode || '/'}`}</p>
                                            <p className={cx('global!ace-u-margin--small-bottom-8')}>
                                                {`${translateScreen('text.location')} ${service.driver?.address?.city || '/'}`}
                                            </p>
                                        </Fragment>
                                        )
                                        }
                                        <p className={cx('global!ace-u-typography--variant-body-medium')}>{translateScreen('text.holder')}</p>
                                        <p>{`${translateScreen('text.first_name')} ${service.owner?.name || '/'}`}</p>
                                        <p>{`${translateScreen('text.surname')} ${service.owner?.surname || '/'}`}</p>
                                        {service.owner?.type !== personTypes.MEMBER && (
                                        <Fragment>
                                            <p>{`${translateScreen('text.street')} ${service.owner?.address?.street || '/'}`}</p>
                                            <p>{`${translateScreen('text.postcode')} ${service.owner?.address?.postCode || '/'}`}</p>
                                            <p className={cx('global!ace-u-margin--small-bottom-8')}>
                                                {`${translateScreen('text.location')} ${service.owner?.address?.city || '/'}`}
                                            </p>
                                        </Fragment>
                                        )
                                        }
                                        <p className={cx('global!ace-u-typography--variant-body-medium')}>{translateScreen('text.case_data')}</p>
                                        <p>{`${translateScreen('text.purpose_of_the_journey')} ${translate(`global.purpose.${service.tripPurpose?.toLowerCase()}`)}`}</p>
                                        <p>{`${translateScreen('text.location_of_damage')} ${composeFullAddress(service.damage?.address)}`}
                                        </p>
                                        <p>{`${translateScreen('text.damage_date')} ${
                                            service.damage?.date
                                                ? moment(service.damage.date, moment.ISO_8601).format('DD.MM.YYYY')
                                                : '/'
                                        }`}
                                        </p>
                                        {(service.type === services.TOWING) && (
                                            <p>{`${translateScreen('text.towing_location')} ${composeFullAddress(service.towing?.address)}`}
                                            </p>
                                        )}
                                        <p>{`${translateScreen('text.service_provider')} ${service.serviceProvider || '/'}`}</p>
                                        <p>{`${translateScreen('text.end_of_recovery_date_timer')} ${service.endOfRecoveryDateTime
                                            ? moment(service.endOfRecoveryDateTime, moment.ISO_8601).format('DD.MM.YYYY')
                                            : '/'}`}
                                        </p>
                                    </Fragment>
                                )}
                                {key === categories.ROADSIDE_ASSISTANCE_AND_TOWING && (
                                    <Fragment>
                                        <p className={cx('global!ace-u-margin--small-bottom-8')}>
                                            {`${translateScreen('text.number_of_passengers')} ${service.numberOfPassengers || '0'}`}
                                        </p>
                                        <p className={cx('global!ace-u-margin--small-bottom-8')}>
                                            {`${translateScreen('text.damage_description')} ${service.comment || '/'}`}
                                        </p>
                                        <p className={cx('global!ace-u-typography--variant-body-medium')}>{`${translateScreen('text.police_accident')} ${service.isAccident ? '/' : ': Nein'}`}</p>
                                        {service.isAccident && (
                                            <Fragment>
                                                <p>{`${translateScreen('text.is_recorded_by_police')} ${service.isRecordedByPolice ? translateScreen('text.yes') : translateScreen('text.no')}`}</p>
                                                {service.isRecordedByPolice && (
                                                    <Fragment>
                                                        <p>{`${translateScreen('text.police_department')} ${service.policeDepartment}`}</p>
                                                        <p className={cx('global!ace-u-margin--small-bottom-8')}>
                                                            {`${translateScreen('text.police_record_number')} ${service.policeRecordNumber || '/'}`}
                                                        </p>
                                                    </Fragment>
                                                )}
                                                <p>{`${translateScreen('text.accident_caused_by_third_party')}
                                                    ${service.thirdParty?.isCausedAccident
                                                    ? translateScreen('text.yes')
                                                    : translateScreen('text.no')}`}
                                                </p>
                                                {service.thirdParty?.isCausedAccident && (
                                                    <Fragment>
                                                        <p>{`${translateScreen('text.surname')} ${service.thirdParty?.name || '/'}`}</p>
                                                        <p>{`${translateScreen('text.street')} ${service.thirdParty?.address?.street || '/'}`}</p>
                                                        <p>{`${translateScreen('text.postcode')} ${service.thirdParty?.address?.postCode || '/'}`}</p>
                                                        <p>{`${translateScreen('text.location')} ${service.thirdParty?.address?.city || '/'}`}</p>
                                                        <p>{`${translateScreen('text.license_plate')} ${service.thirdParty?.licensePlate || '/'}`}</p>
                                                        <p>{`${translateScreen('text.insurance')} ${service.thirdParty?.insuranceName || '/'}`}</p>
                                                        <p>{`${translateScreen('text.third_party_insurance_number')} ${service.thirdParty?.insuranceNumber || '/'}`}</p>
                                                        <p className={cx('global!ace-u-margin--small-bottom-8')}>
                                                            {`${translateScreen('text.third_party_damage_number')} ${service.thirdParty?.damageNumber || '/'}`}
                                                        </p>
                                                    </Fragment>
                                                )}
                                            </Fragment>
                                        )}
                                        <p>{`${translateScreen('text.is_alcohol_test_done')} ${service.isAlcoholTestDone ? translateScreen('text.yes') : translateScreen('text.no')}`}</p>
                                        {service.isAlcoholTestDone && (
                                            <p className={cx('global!ace-u-margin--small-bottom-8')}>{`${translateScreen('text.alcohol_test_result')} ${service.alcoholTestResult || '/'}`}</p>
                                        )}

                                        <p>{`${translateScreen('text.is_insurance_covers_costs')} ${service.isInsuranceCoversCosts ? translateScreen('text.yes') : translateScreen('text.no')}`}</p>
                                        {service.isInsuranceCoversCosts && (
                                            <Fragment>
                                                <p>{`${translateScreen('text.insurance_name')} ${service.insuranceName || '/'}`}</p>
                                                <p className={cx('global!ace-u-margin--small-bottom-8')}>{`${translateScreen('text.insurance_number')} ${service.insuranceNumber || '/'}`}</p>
                                            </Fragment>
                                        )}
                                        <p>{`${translateScreen('text.is_third_party_cost')} ${service.thirdParty?.isCoveringCosts ? translateScreen('text.yes') : translateScreen('text.no')}`}</p>
                                        {service.thirdParty?.isCoveringCosts && (
                                            <Fragment>
                                                <p>{`${translateScreen('text.third_party_costs_covered_by')} ${service.thirdParty?.costsCoveredBy || '/'}`}</p>
                                                <p>{`${translateScreen('text.third_party_costs_purpose')} ${service.thirdParty?.costsPurpose || '/'}`}</p>
                                                <p className={cx('global!ace-u-margin--small-bottom-8')}>
                                                    {`${translateScreen('text.third_party_costs_amount')} ${service.thirdParty?.costsAmount || '/'}`}
                                                </p>
                                            </Fragment>
                                        )}
                                        <p>{`${translateScreen('text.drivers_license_valid')} ${service.isDriversLicenseValid ? translateScreen('text.yes') : translateScreen('text.no')}`}</p>
                                    </Fragment>
                                )}
                                {(key === categories.OTHER || key === categories.AID) && (
                                    <p>{`${translateScreen('text.what_happened')} ${service.comment || '/'}`}</p>
                                )}
                                {key === categories.JOURNEY_CONTINUATION_AND_ACCOMMODATION && (
                                    <Fragment>
                                        <p>
                                            {`${translateScreen('text.service_type')} ${translate(`global.service_type.${service.type?.toLowerCase()}`) || '/'}`}
                                        </p>
                                        {(service.type === serviceTypes.SHORT_TRIP
                                            || service.type === serviceTypes.FURTHER_JOURNEY) && (
                                            <Fragment>
                                                <p>
                                                    {`${translateScreen('text.journey_date')} ${service.startDateTime
                                                        ? moment(service.startDateTime, moment.ISO_8601).format('DD.MM.YYYY')
                                                        : '/'}`}
                                                </p>
                                                <p>
                                                    {`${translateScreen('text.from')} ${typeof service.startingAddress !== 'object'
                                                        ? service.startingAddress
                                                        : composeFullAddress(service.startingAddress)
                                                    }`}
                                                </p>
                                                <p>
                                                    {`${translateScreen('text.to')} ${typeof service.destinationAddress !== 'object'
                                                        ? service.destinationAddress
                                                        : composeFullAddress(service.destinationAddress)
                                                    }`}
                                                </p>
                                                <p>
                                                    {`${translateScreen('text.notes')} ${service.comment || '/'}`}
                                                </p>
                                            </Fragment>
                                        )}
                                        {service.type === journeyContinuationServices.ACCOMMODATION && (
                                            <Fragment>
                                                <p>
                                                    {`${translateScreen('text.from')} ${
                                                        service.startDateTime
                                                            ? moment(service.startDateTime, moment.ISO_8601).format('DD.MM.YYYY')
                                                            : '/'
                                                    }`}
                                                </p>
                                                <p>
                                                    {`${translateScreen('text.to')} ${
                                                        service.endDateTime
                                                            ? moment(service.endDateTime, moment.ISO_8601).format('DD.MM.YYYY')
                                                            : '/'
                                                    }`}
                                                </p>
                                                <p>
                                                    {`${translateScreen('text.number_of_people')} ${service.numberOfPeople || '/'}`}
                                                </p>
                                                <p>
                                                    {`${translateScreen('text.surname')} ${service.name || '/'}`}
                                                </p>
                                                <p>
                                                    {`${translateScreen('text.notes')} ${service.description || '/'}`}
                                                </p>
                                            </Fragment>
                                        )}
                                        {service.type === serviceTypes.RENTAL_CAR && (
                                            <Fragment>
                                                <p>{translateScreen('text.period_of_rental')}</p>
                                                <p>
                                                    {`${translateScreen('text.from')} ${
                                                        service.startDateTime
                                                            ? moment(service.startDateTime, moment.ISO_8601).format('DD.MM.YYYY')
                                                            : '/'
                                                    }`}
                                                </p>
                                                <p>{`${translateScreen('text.to')} ${
                                                    service.endDateTime
                                                        ? moment(service.endDateTime, moment.ISO_8601).format('DD.MM.YYYY')
                                                        : '/'
                                                }`}
                                                </p>
                                                <p>
                                                    {`${translateScreen('text.pickup_location')} ${composeFullAddress(service.startingAddress)}`}
                                                </p>
                                                <p>
                                                    {`${translateScreen('text.return_location')} ${composeFullAddress(service.destinationAddress)}`}
                                                </p>
                                                <p>
                                                    {`${translateScreen('text.is_vehicle_delivered')} ${service.isDelivered ? translateScreen('text.yes') : translateScreen('text.no')}`}
                                                </p>
                                                <p>
                                                    {`${translateScreen('text.notes')} ${service.comment || '/'}`}
                                                </p>
                                            </Fragment>
                                        )}
                                    </Fragment>
                                )}
                            </SectionDetails>
                        </AccordionRow>
                    ))}
                </Fragment>
            ))}
            <SectionDetails isEditable={isEditable}>
                <p>{translateScreen('text.documents')}</p>
                {files?.map(({fileName, id}) => {
                    return (
                        <p key={id}>{fileName}</p>
                    );
                })}
                <p>{`${translateScreen('text.remarks')} ${invoiceSubmission.invoicingRemarks || invoicingRemarks || '/'}`}</p>
            </SectionDetails>
            <ServiceCategoryTitle className={cx(['global!ace-u-margin--small-bottom-24'])} icon={paymentIcon}>
                {translateScreen('title.bank_details')}
            </ServiceCategoryTitle>
            <SectionDetails
                className={cx('ace-c-section-details--bordered', 'global!ace-u-padding--small-16')}
                isEditable={isEditable}
            >
                <p>{`${translateScreen('text.surname')} ${paymentInfo.bankAccountOwner}`}</p>
                <p>{`${translateScreen('text.iban')} ${maskIban(paymentInfo.iban)}`}</p>
                <p>{`${translateScreen('text.bic_swift')} ${paymentInfo.swift || '/'}`}</p>
            </SectionDetails>
        </Fragment>
    );
};

InvoiceSummaryScreen.propTypes = {
    files: PropTypes.array,
    invoiceSubmission: PropTypes.object.isRequired,
    isEditable: PropTypes.bool,
    invoicingRemarks: PropTypes.string,
    paymentInfo: PropTypes.object,
};

InvoiceSummaryScreen.defaultProps = {
    files: [],
    isEditable: true,
    invoicingRemarks: '',
    paymentInfo: {},
};


export default InvoiceSummaryScreen;
